.App {
  text-align: center;
  font-family: 'Onest', sans-serif;
  min-height: 100%;
  font-size: 14px;
  line-height: 1.6em;
  color: #151727;
}

h1 {
  font-family: 'Onest',sans-serif;
  font-size: 32px;
  line-height: 1.2;
  padding-bottom: .3em;
  font-weight: 700;
}

h2 {
  font-family: 'Onest',sans-serif;
  font-size: 32px;
  line-height: 1.2;
  padding-bottom: .3em
}

.App-logo {
  margin-top:20px;
  max-height: 10vmin;
  pointer-events: none;
  margin-bottom:50px;
}

.App-logo-ahs {
  background-color: #202946;
}

.App-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.Text-left {
  text-align: left;
}

.Top-padding {
  padding-top: 20px;
}

.Headline {
  max-width: 800px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.Hide {
  display: none;
}

.Input {
  padding: 5px 0 5px 0;
}

.Footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 2.5rem;
}

.Footer-bottom {
  text-align: center;
  background-color: #E8E4D3;
  color: #2b2b2b;
  padding: 2rem 0 2rem 0;
}

.Footer-bottom-ahs {
  background-color: #F5EFD7;
  color: #151727;
}

.Footer-margin {
  max-width: 64rem;
  margin: 0 auto 0 auto;
  font-size: .75rem;
  font-weight: 400;
  line-height: .875rem;
}

.Footer-margin a {
  padding: 0 .5rem 0 .5rem;
  color: #2b2b2b;
  line-height: 1;
  font-weight: 700;
  margin-top: auto;
}

.Footer-margin-ahs a {
  color: #151727;
}

.Footer-margin a:link {
  text-decoration: none;
}

.Footer-margin a:hover {
  text-decoration: underline;
}

.Footer-terms {
  text-align: center;
  padding: 5px 20px 5px 20px;
  font-size: .9em;
}

.Loading {
  height:100%;
  padding: auto auto auto auto;
  background-color: black;
  z-index: 100000;
}

.Max-width {
  max-width: 64rem;
  margin: 0 auto 0 auto;
  min-height: 24rem;
  padding-bottom: 2.4rem;
}

.Top-margin {
  margin: 2rem;
}

.btn-primary {
  background-color: #F34113;
  border-color: #F34113;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #dd3a11 !important;
  border-color: #dd3a11 !important;
}

.radio {
  text-align: left;
}

.container {
  max-width: 800px;
  min-height: 100%;
  padding-bottom: 5rem;
  position: relative;
  min-height: 100vh;
  margin-top: 50px;
}

body {
  height: 100%;
}

html {
  height: 100%;
}

input[type="radio"] {
  margin-right: 10px;
}

.Help-text {
  height: 50%;
  font-weight: bold;
}

.Help-link {
  height: 50%;
}

.Help-row {
  margin-top: 5em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
